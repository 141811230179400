<template>
	<div id="basicset" class="el-content">
		<a-tabs v-model:activeKey="active" @change="changeActive">
		    <a-tab-pane key="domain" tab="域名设置">
				<div class="tips">
					<p>提示</p>
					<p>1、域名填写保存之后需要提交审核。</p>
					<p>2、审核通过后，请将您的域名解析 CNAME 记录到 {{site_pc_domain}}</p>
				</div>
				<a-form :label-col="{ span: 2 }" :wrapper-col="{ span: 8 }">
					<a-form-item>
						<template #label>
							<span>官网域名审核</span>
						</template>
						<a-input v-model:value="form.domain_apply"></a-input>
						<div v-if="form.audit != -2 && form.audit != ''">
							<p style="color: #66b1ff;" v-if="form.audit == 0 || form.audit == 3 ">提示：您的域名正在审核中！</p>
							<p style="color: red;" v-if="form.audit == 2">拒绝原因：{{appsitereason}}</p>
							<p style="color: #85CE61;" v-if="form.audit == 1">提示：您的域名审核成功！(请勿重复提交)</p>
						</div>
					</a-form-item>
					<a-form-item :wrapper-col="{ offset: 2 }">
						<a-button v-if="form.audit == -2 || form.audit == 2 || form.audit == 1 || form.audit == ''" type="primary" @click="submitSave">提交</a-button>
					</a-form-item>
				</a-form>
			</a-tab-pane>
		    <a-tab-pane key="topNavigation" tab="顶部导航">
				<a-form :label-col="{ span:2 }" :wrapper-col="{ span: 16 }">
					<a-form-item label="导航样式">
						<a-radio-group v-model:value="website_hander_nav.website_hander_type">
							<a-radio value="1">样式一</a-radio>
							<a-radio value="2">样式二</a-radio>
						</a-radio-group>
					</a-form-item>
					<a-form-item :wrapper-col="{ offset: 2 }">
						<topbar :topbarList='website_hander_nav'></topbar>
					</a-form-item>
					<a-form-item label="页面标题">
						<a-input v-model:value="website_hander_nav.website_hander_title" placeholder="请填写页面标题"></a-input>
					</a-form-item>
					<a-form-item label="开放注册">
						<a-radio-group v-model:value="website_hander_nav.website_hander_register">
						    <a-radio value="1">是</a-radio>
						    <a-radio value="0">否</a-radio>
						</a-radio-group>
					</a-form-item>
					<a-form-item label="logo图片">
						<kd-img-select :src="website_hander_nav.website_hander_logo" @change="e=>website_hander_nav.website_hander_logo = e"></kd-img-select>
					</a-form-item>
					<div style="margin-left: 130px;margin-bottom: 18px;font-size: 14px;">
						<p style="color: red;">提示：logo大小建议(205×70)</p>
					</div>
					<a-form-item label="导航标题" v-if="website_hander_nav.website_hander_type == 2">
						<div v-for="(item,index) in website_hander_nav.website_hander_list" :key='index' style="width: 45%;display: flex;align-items: center;margin-bottom: 15px;">
							<a-input v-model:value="item.title"></a-input>
							<a-input v-model:value="item.path">
								<template #addonAfter>
									<div  @click="showLink(index)">选择链接</div>
								</template>
							</a-input>
							<div style="margin-left: 20px;">
								<a-button v-if="index == 0 && website_hander_nav.website_hander_list.length <= 5" type="primary" @click="moduleCreate">新增</a-button>
								<a-button v-else type="danger" @click="reducemoduleCreate(index)">删除</a-button>
							</div>
						</div>
					</a-form-item>
					<a-form-item :wrapper-col="{ offset: 2 }">
						<a-button type="primary" @click='submitSave'>保存</a-button>
					</a-form-item>
				</a-form>
			</a-tab-pane>
		    <a-tab-pane key="footer" tab="官网底部">
				<a-form :label-col="{ span: 2 }" :wrapper-col="{ span: 8 }">
					<a-form-item label="公众号">
						<kd-img-select :src="website_hander_fot.accounturl" @change="e=>website_hander_fot.accounturl = e"></kd-img-select>
					</a-form-item>
					<a-form-item label="微信">
						<kd-img-select :src="website_hander_fot.wechaturl" @change="e=>website_hander_fot.wechaturl = e"></kd-img-select>
					</a-form-item>
					<a-form-item label="IOS下载">
						<kd-img-select :src="website_hander_fot.iosurl" @change="e=>website_hander_fot.iosurl = e"></kd-img-select>
					</a-form-item>
					<a-form-item label="Android下载">
						<kd-img-select :src="website_hander_fot.androidurl" @change="e=>website_hander_fot.androidurl = e"></kd-img-select>
					</a-form-item>
					<a-form-item label="小程序二维码">
						<div style="width: 70%;display: flex;align-items: center;">
							<a-input v-model:value="website_hander_fot.codetitle" placeholder="请填写小程序名称"></a-input>
							<kd-img-select :src="website_hander_fot.codeurl" @change="e=>website_hander_fot.codeurl = e"></kd-img-select>
						</div>
					</a-form-item>
					<a-form-item label="手机号码">
						<a-input v-model:value="website_hander_fot.fotphone" placeholder="请填写手机号码"></a-input>
					</a-form-item>
					<a-form-item label="工作时间">
						<a-input v-model:value="website_hander_fot.fotdate" placeholder="请填写工作时间"></a-input>
					</a-form-item>
					<a-form-item label="邮箱">
						<a-input v-model:value="website_hander_fot.mailbox" placeholder="请填写工作邮箱"></a-input>
					</a-form-item>
					<a-form-item label="底部备案">
						<a-input v-model:value="website_hander_fot.record" placeholder="请填写底部备案"></a-input>
					</a-form-item>
					<a-form-item :wrapper-col="{ offset: 2 }">
						<a-button type="primary" @click='submitSave'>保存</a-button>
					</a-form-item>
				</a-form>
			</a-tab-pane>
		</a-tabs>
		
		<webLink :isShow="linkShow" @surePathSelect="surePathSelect"></webLink>
	</div>
</template>

<script>
import webLink from '@/components/webassembly/webLink.vue'
import topbar from '@/components/webassembly/TopBar.vue'
import { reactive, toRefs } from 'vue'
import saasSetModel from '@/api/saas/set'
import setModel from '@/api/set'
export default{
	components:{
		topbar,
		webLink
	},
	setup(){
		const state = reactive({
			form:{
				domain_apply:'',
				audit: ''
			},
			website_hander_nav:{
				website_hander_type: 1,
				website_hander_title: '',
				website_hander_logo: 'https://we.cqkundian.com/addons/kundian_farm/resource/home/images/goods-3.jpg',
				website_hander_register: 1,
				website_hander_list:[
					{
						title: '导航标题',
						path: ''
					}
				]
			},
			website_hander_fot:{
				accounturl: '',
				wechaturl: '',
				iosurl: '',
				androidurl:'',
				codetitle: '',
				codeurl: '',
				fotphone: '',
				fotdate: '',
				mailbox: '',
				record: ''
			},
			rules:{},
			active: 'domain',
			disabled: false,
			appsitereason: '',
			site_pc_domain: '',
			imageVisible:false,  //图片显示
			linkShow: false,
			currentListIndex: 0
		})

		getWebsite()
		saasSetModel.getSiteSet(['site_pc_domain'],res=>{
			state.site_pc_domain = res.site_pc_domain || ''
		})

		function changeActive(e){
			let {website_hander_nav,website_hander_fot,active} = state, data = []
			if( active == 'topNavigation' ) data = Object.keys(website_hander_nav)
			if( active == 'footer' ) data = Object.keys( website_hander_fot) 
			setModel.getFarmSet(data).then(res=>{
				if(active == 'topNavigation'){
					state.website_hander_nav =res;
					if( state.website_hander_nav.website_hander_list ){
						state.website_hander_nav.website_hander_list = JSON.parse(state.website_hander_nav.website_hander_list)
					}else{
						state.website_hander_nav.website_hander_list= [ { title: '导航标题', path: '' } ]
					}
				}
				if(active == 'footer'){
					state.website_hander_fot = res;
				}
			})
		}

		function getWebsite(){
			saasSetModel.getWebSite(res=>{
				console.log('111',res);
				
				if( res ){
					state.form = {
						domain_apply:res.domain_apply || '',
						audit: res.audit || ''
					}
					state.appsitereason = res.reason
					state.disabled = res.audit == 0 ? true:false
				}
			})
		}

		function submitSave(){
			let {form,active,website_hander_nav,website_hander_fot} = state
			if(active == 'domain'){
				saasSetModel.setWebSite({domain_apply:form.domain_apply})
				return
			}
			if(active == 'topNavigation') setModel.setFarmSet(website_hander_nav)
			if(active == 'footer') setModel.setFarmSet(website_hander_fot)
		}

		function showLink(ind){
			state.linkShow = true
			state.currentListIndex = ind
		}

		function surePathSelect(path){
			if( path ){
				state.website_hander_nav.website_hander_list.map((item,index)=>{
					state.website_hander_nav.website_hander_list[state.currentListIndex].path = path
				})
				state.linkShow =false
			}else{
				state.linkShow =false
			}
		}

		const moduleCreate = ()=>state.website_hander_nav.website_hander_list.push({title:'导航标题',path:''})

		const reducemoduleCreate = index=>state.website_hander_nav.website_hander_list.splice(index,1)

		return{
			...toRefs(state),
			changeActive,
			getWebsite,
			submitSave,
			moduleCreate,
			reducemoduleCreate,
			showLink,
			surePathSelect
		}
	}
}
</script>

<style lang="scss">
</style>
